.App {
  text-align: center;
}

html {
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
  background-image: url(../src/img/Poza_desktop_header.png);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
}
@media (min-width:320px) and (max-width:481px)  {
  .main {
    background-image: url(../src/img/Header-mobile.webp);
    background-position: center;
    height: 88vh;
  }

  .main-logo{
    display: none;
  }

  .main-logo-mobile{
    width: 100px;
    margin-top: 70%;
  }

  .hashtag{
    width: 20em;    
    margin-top: 60% !important;
  }

  .nodisp{
    display: none;  
  }

  .container-button{
    display: none;  
  }

  /* .centered{
    display: none;  
  } */

  .nav-homepage-lang{
    display: none;
  }

  .list-lang-desktop{
    display:none;
  }

  .voluntariat-header{
    font-size: 45px !important;
  }

  .card-preventie{
    width: 100% !important;
  }

  .jumbo-arbore-white {
    margin-top: 60% !important;
  }
  
  .container-chei .border-right{
    border: none !important;
    height: auto;
  }

  .container-programe{
    background: linear-gradient(to right, rgba(245, 246, 252, 0.01), rgb(151, 143, 149)) !important;

    }
}
@media (min-width:481px) {
  .main-logo{
    position: center;
    width: 400px !important;
    max-width: 500px !important;
    margin-top: 15%;
  }

  .main-logo-mobile{
    display:none;
  }

  .navbar-home{
    display: none;
  }


  .hashtag{
    width: 35rem;    
  }

  .btn-main{
    font-family: Poppins; 
    border-radius: 0rem !important;
    position: relative;
    letter-spacing: 2px;
  }

  .main-btn-svg{
    width: 200px;
  }

  .container-button{
    position: relative;
  }

  .centered{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%); 
  }

  .centered2{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
  }

  .container-button a{
    color: white;
    letter-spacing: 2px;
    font-size: 25px;
  }

  .container-button a:hover{
    color:red;
  }

  .list-lang-mobile{
    display: none;
  }

}

@media (min-width:768px) and (max-width:1025px) {
  .arborepic img{
    display: none;
  }

  .poppins-white{
   font-size: 20px !important;
  }

  .centered{
    top: 70%;
  }

  .container-cards .card-title{
    font: 10px Poppins;
  }

}

.nav-homepage-lang{
  justify-content: flex-end;
  padding-right: 10%;
}


.btn-main .btn-group-lg>.btn, .btn-lg{
  font-size: 1.5rem !important;
  padding: .3rem 2.2rem !important;
}


.btn-main::before,
.btn-main::after {
  content: " ";
  position: absolute;
  background: rgb(37,37,37);
  top: 14px;
  height: 16px;
  width: 1px;
}

 .btn-outline-light:hover {
  color: white !important;
  background-color: transparent !important;
  border-color: red !important;
}

.btn-main::before {
  left: -1px;
}

.btn-main::after {
  right: -1px;
}

.container-menu{
  background-color: rgb(8, 8, 8);
  justify-content: center;
  max-width: 540px !important;
  height: 100%;
}

.sidebar li .submenu{ 
	list-style: none; 
	margin: 0; 
	padding: 0; 
	padding-left: 1rem; 
	padding-right: 1rem;
}
.sidebar .nav-link {
    font-weight: 900;
    font-size: 1.3rem;
    color: white;
    font-family: Poppins; 
    letter-spacing: 3px;
    padding: 0.8rem !important;

}
.sidebar .nav-link:hover {
    color: red;
}

.nav-link:hover, .nav-link:focus {
  color: red !important;
}

.nav, .card{
  background-color: rgb(8, 8, 8) !important;
}

.sidebar{
  margin-top: 20% !important;
}

hr{
  margin: auto !important;
  background-color: white !important;
}

.bg-darkk{
  background-color: black !important;
}



.navbar-brand img{
  width: 200px;
  padding-left: 10%!important;
  padding-top: 5px !important;
}

.nav-vertical{
  padding: 3%;
}

.nav-vertical .nav-link{
  color: white !important;
}

.nav-vertical .nav-link:hover{
  color: red !important;
}

.nav-vertical .nav-link {
  font-weight: 300;
  font-size: 0.9rem;
  color: white;
  font-family: Poppins; 
  letter-spacing: 0.5px;

}

.nav-vertical li { 
	list-style: none; 
}

/*
 * Presentational Styles
 */

 html,
 body {
   font: caption;
   background-color: #f1f1f1;
   position: relative;
   height: 100%;
 }
 
 .centerer {
   position: absolute;
   top: 60%;
   left: 50%;
   transform: translate3d(-50%, -50%, 0);
 }
 
 /* h4 {
   font-weight: 200;
   text-align: center;
   font-size: 18px;
   margin-bottom: 20px;
 } */
 
 p {
   margin-top: 25px;
   font-size: 13px;
   color: #3366cc;
   text-align: center;
 }
 
 p a {
   color: inherit;
   text-decoration: none;
 }
 
 p a:hover {
   text-decoration: underline;
 }
 
 
 .clear{
  clear: both;
}


.slide-toggle{
  display: none;
}

.slidemenu{
  font-family: arial, sans-serif;
  max-width: 400px;
  overflow: hidden;
}

.slidemenu label{
  width: 25%;
  text-align: center;
  display: block;
  float: left;
  color: white;
  opacity: 0.2;

}

.slidemenu label:hover{
  cursor: pointer;
  color: white;
}

.slidemenu label span{
  display: block;
  padding-left: 50px;    
}


/*Bar Style*/

.slider{
  width: 100%;
  height: 5px;
  display: block;
  background: #ccc;
  margin-top: 5px;
  border-radius: 5px;
}

.slider .bar{
  width: 50%;
  height: 5px;
  background: #333;
  border-radius: 5px;
}

/*Animations*/
.slidemenu label, .slider .bar {
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
}

/*Toggle*/

.slidemenu .slide-toggle:checked + label{
  opacity: 1;
}



.slidemenu #slide-item-1:checked ~ .slider .bar{ margin-left: 0; }
.slidemenu #slide-item-2:checked ~ .slider .bar{ margin-left: 50%; }



.bgc-dark {
  background-color: black;
}

.abuz {
  background-image: url(../src/img/Abuz/AbuzHeaderimage.webp);
  background-size: cover;
  height: 100vh;
  width: 100%;
  background-position: center;
}

.arborele {
  background-image: url(../src/img/Aborele/Arboreleheader.webp);
  background-size: cover;
  height: 90vh;
  width: 100%;
  background-position: center;
}

.arborele-scopul {
  background-size: cover;
  height: 50vh;
  width: 100%;
  background: transparent linear-gradient(90deg, #19110E 20%, #2D2521F5 80%) 0% 0% no-repeat padding-box;
}

.abuz-header{
  color: red;
  letter-spacing: 6px;
  font: normal normal 600 25px/36px Poppins;
}

@media (max-width:481px){
  .abuz-header-hashtag{
    font-size: 30px !important;
  }

  .card{
    margin-top: 10% !important;
  }
}

.abuz-header-hashtag{
  color: white;
  font-family: Dcc;
  font-size: 80px;
  letter-spacing: 10px;
}


.abuz p{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-27) var(--unnamed-font-family-poppins);
    color: white;
    text-align: left;
    font: normal normal normal 18px Poppins;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;

}

.btn-outline-danger{
  color:white !important;
  border-radius: 0px;
}

section{
  background-color: white !important;
}

.cards-container h1{
  font-size: 30px;
  font-family: Poppins;
  font-weight: 800;
  letter-spacing: 6px;
}

.card{
  border: none;
  width: 70%;
  justify-content: center;
  margin: auto;
}

.cards .card{
  background-color: white !important;
}

.card-title{
  background-color: #000;
  padding: 10px;
  color: white;
  font: 20px Poppins;
  letter-spacing: 2.6px;
  text-transform: uppercase;
  font-weight: 900;
  opacity: 1;
}

.card-body{
  padding: 0px !important;
}

.card-text{
  color: black;
  text-align: left;
}

.card-body h6{
  font-weight: 900 !important;
}

.card-body ul{
  padding-left: 1rem !important;
}

.card-body li{
  padding-top: 5%;
}

.card-body a{
  color: red;
}

.container-cards{
  align-content: center;
}

.dcc{
  font-family: Dcc;
  font-size: 100px;
  color:white;
  letter-spacing: 10px;
}

.poppins{
  font-family: Poppins; 
  font-size: 30px;
  color: red;
  font-weight: 900;
  letter-spacing: 3px;
  
}

.poppins-white{
  font-family: Poppins; 
  font-size: 24px;
  color: white;
  font-weight: 800;
}

.poppins-bold-black{
  font-family: Poppins; 
  color: black;
}

.poppins-font{
  font-family: Poppins;
}

@media (min-width:320px) and (max-width:481px)  {
  .poppins-white{
    font-family: Poppins; 
    font-size: 15px;
    color: white;
    font-weight: 800;
  }

  .arborepic img{
    width: 350px !important;
  }

}

.poppins-white-sm{
  font-family: Poppins; 
  font-size: 18px;
  color: white;
  font-weight: 200;
}

.card-img-top{
  width: auto !important;
  height: 200px !important;
}

.breakline{
  white-space: pre-line;
}

.arborepic img{
  width: 550px;
}

.jumbo-arbore-white{
  background-size: cover;
  height: 40vh;
  width: 100%;
  background-position: center;
}

.voluntariat {
  background-size: cover;
  height: 80vh;
  width: 100%;
  background-position: center;
  background-image: url("../src/img/Voluntariat/Anca-cutie2.png");
}

.voluntariat-header{
  font-size: 65px;
}

.voluntariat-header2{
  font-size: 30px !important;
}

.voluntariat-header-text{
  margin-top: 15%;
}

.container-white{
  height: 10vh;
  width: 100%;
}

.profilul-voluntarului{
  height: 40%;
  width: 100%;
  background-color: #E5E5E5;
}

.info-contact{
  height: 23vh !important;
  background-color: #000;
}

.btn-outline-danger-full{
  color:white !important;
  border-radius: 0px;
  background-color: red;
  font-family: Poppins;
}

.container-cards-voluntariat .card{
  background-color:#d1d0d0 !important;
  font-family:Poppins;
}

.container-cards-voluntariat .card-title{
  background-color:#d1d0d0 !important;
  font-family: Libre;
  color: #000;
}

.container-cards-voluntariat .card-title span{
  color: red;
  font-weight: 900;
  font-size: 40px;
}

.container-cards-voluntariat .card{
  width: 90% !important;
  height: 100% !important;
}

.container-cards-voluntariat hr{
  width: 90%;
  background-color: red !important;
  height: 2px;
}

.container-cards-voluntariat .card-text li span{
  font-weight: 900;
}

.container-cards-voluntariat .card-text li{
  list-style: none !important;
}

.container-cards-voluntariat .container-more{
    display:flex;
    justify-content:center;
    align-items: center;
    margin-top: 30%;
    color:red;
    font-weight: 900;
}

.info-contact2{
  height: 70vh;
  background:url('../src/img/Voluntariat/hands2.png');
  background-size: cover;
  background-position: center;
  color: white;
}


.img-friends{
  align-items: flex-end;
  filter: grayscale(1);
}

.container-chei{
  height: auto !important;
  color: white;
  font-family: Poppins;
  text-align: left !important;
}

.container-chei p, .container-chei li{
  color: white;
  font-size: 16px;
  text-align: left !important;
  margin-left: 5%;
  margin-right: 5%;
}

.container-chei h3{
  color: #ECC295;
  margin-left: 5%;
  font-family: Libre;
}

.container-chei span{
  color: #ECC295;
  font-size: 18px;
  text-align: left !important;
}

.container-chei .read-more{
  margin-top: 10%;
}
 
 .border-right{
   border-right: 1px solid #ECC295;
   height: auto;
   margin-top: 2%;
 }

 .container-chei img{
   width: 15px;
 }

 .container-chei .p-chei{
  color: #ECC295;
  font-size: 18px;
  text-align: left !important;
 }

.container-chei a{
  text-decoration: none !important ;
}

.container-chei .read-more-link {
  /* padding-top: 60%; */
}

.preventie {
  background-size: cover;
  height: 100%;
  width: 100%;
  background-position: center;
}

.preventie-header-text{
  text-align: left !important;
}

.container-campanii{
  font-family: Poppins;
}

.container-campanii hr{
  background-color: #EA0029 !important;
  width: 20% !important;
  height: 10px !important;
  opacity: 10;
}

.card-preventie{
  /* margin: 0 !important; */
  width: 50%;
  background-color: white !important;
  margin-right: 5%;
  margin-top: 5%;
}


.card-preventie .card-img-top {
  width: auto !important;
  height: auto !important;
  filter: grayscale(1);
}

.card-preventie p{
  margin: 5%;
}


.card-preventie .card-title{
  background-color: white;
  color: black;
}

.card-preventie span{
  color: red;
  font-weight: 900;
  font-size: 40px;
}

.card-preventie .btn-implicate{
  color: red !important;
  margin: 5%;
}

.card-preventie .btn-implicate:hover{
  color: black !important;
}

.card-preventie .card-footer{
  background-color: white;
  border-top: none;
}
.container-programe{
background: linear-gradient(to right, rgba(245, 246, 252, 0.01), rgb(151, 143, 149)),
url('../src/img/Preventie/preventie.png') ;
background-position: cover;
background-size: contain;
background-repeat:no-repeat;
}

.container-programe h2{
  font-family: Poppins;
}

.container-programe h5{
  font-family: Libre !important;
  color: red;
}


.card-programe{
  background-color: white !important;
  border-left: 10px solid #EA0029;
}

.card-programe .card-text{
  margin: 7%;
  font-family: Poppins;
}

.card-programe span{
  color: red;
}

.card-programe hr{
  background-color: gray !important;
  margin-top: 3% !important;
}

/*Connect*/
form{
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  border: 2px solid red;
  border-radius: 10px;
}

.container-connect{
  padding-top:5% !important;
  padding-bottom: 5% !important;
}

.bold-red{
  font-family: Poppins;
  color: white;
}

.container-connect a{
  color: red;
}

.form-group{
  color: white;
}

.form-group label{
  margin-top: 5%;
  margin-bottom: 2%;
}

.footer-jumbotron{
  height: 20%;
}

.footer-jumbotron i{
  color: red;
}

.footer-jumbotron img{
  width: 200px;
}

.footer-lower{
  border-top: 1px solid rgba(255, 255, 255, 0.151);
}

.footer-lower p{
  color: white;
  font-family: Poppins;
}

.jumbotron-comingsoon{
  height: 100vh;
}

.jumbotron-comingsoon img{
  width: 15%;

}

.mail-footer a{
  text-decoration: none;
  letter-spacing: 1px;
  font-family: Poppins;

}